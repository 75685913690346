<template>
    <AccountHeader />
    <UMain>
        <UContainer>
            <UPage>
                <template #left>
                    <UAside>
                        <UAsideLinks :links="links" class="pt-0" :ui="{ wrapper: 'space-y-4' }" />
                    </UAside>
                </template>
                <div class="pt-8 mb-20">
                    <slot />
                </div>
            </UPage>
        </UContainer>
    </UMain>
</template>

<script setup>

const links = [{
    label: 'Home',
    to: '/home',
    exact: true,
}, {
    label: 'Claims',
    to: '/home/claims',
}, {
    label: 'Billing & Payments',
    to: '/home/billing',
}];
</script>